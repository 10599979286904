.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;

    @media only screen and (max-width: 1035px) {
      width: 70%;
      margin-left: 60px;
    }
  }

  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      margin-top: -10px;
      margin-left: 10px;
      margin-bottom: -16px;
      margin-right: -5px;
      opacity: 0;
      width: 80px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 16px 10px 20px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}

.tech-logo-cont {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 27%;
  top: 46.5%;
  transform: translateY(-50%);
  width: 804px;
  margin-left: 0px;
  cursor: text;
  transition-duration: 0.2s;

  img {
    height: 100px;
    margin: 16px 17px;
  }

  @media only screen and (max-width: 1440px) {
    left: 40%;
    top: 35%;
    width: 402px;
    margin: 0 15px;
  }

  @media only screen and (max-width: 1035px) {
    left: 8.4%;
    top: 92%;
    width: 460px;

    img {
      height: 45px;
      margin-inline: 8px;
    }
  }

  @media only screen and (max-width: 600px) {
    display: none
  }
}