.logo-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.logo-animate-hover {

  &:hover {
    transform: scale(1.5)
  }
}

@for $i from 1 through 50 {
  .logo-animate._#{$i} {
    animation-delay: #{$i / 10}s;
  }
}