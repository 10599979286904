* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.page-contain {
  position: absolute;
  top: 28px;
  right: 0px;
  bottom: 0px;
  left: 60px;
  font-family: 'Open Sans', sans-serif;

  @media only screen and (max-width: 788px) {
    top: 18px;
  }
}

.card-contain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.card {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 1.5em 2.5em -.5em rgba(#ffffff, .1);
  margin-bottom: 25px;

  @media only screen and (max-width: 788px) {
    margin-inline: 12px;
    margin-bottom: 15px;
  }

  .data-card {
    display: flex;
    flex-direction: column;
    width: 269px;
    height: 265px;
    overflow: hidden;
    border-radius: .5em 0 0 .5em;
    background: #FFD700;
    padding: 2.75em 2.5em;
  
    @media only screen and (max-width: 788px) {
      width: 100%;
      border-radius: .5em .5em 0 0 ;
    }
    
    h3 {
      color: #2E3C40;
      font-size: 3.5em;
      font-weight: 600;
      line-height: 1;
      padding-bottom: .5em;
      margin: 0 0 0.142857143em;
      border-bottom: 2px solid #431902;
      transition: color .45s ease, border .45s ease;
    }
  
    h4 {
      color: #627084;
      text-transform: uppercase;
      font-size: 1.125em;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.1em;
      margin: 0 0 1.777777778em;
      transition: color .45s ease;
  
      @media only screen and (max-width: 788px) {
        font-size: 1.6rem;
      }
    }
  
    p {
      opacity: 0;
      color: #FFFFFF;
      font-weight: 600;
      line-height: 1.8;
      margin: 0 0 1.25em;
      transform: translateY(-1em);
      transition: opacity .45s ease, transform .5s ease;
  
      @media only screen and (max-width: 788px) {
        opacity: 1;
        color: #627084;
        font-size: 1.4rem;
        line-height: 1.5;
        margin-top: 15px;
      }
    }
  
    .link-text {
      display: flex;
      color: #431902;
      font-size: 1.125em;
      font-weight: 600;
      line-height: 1.2;
      margin: auto 0 0;
      transition: color .45s ease;
  
      svg {
        margin-left: .5em;
        transition: transform .6s ease;
        
        path {
          transition: fill .45s ease;
        }
      }
    }
  }
  
  .image-card {
    img {
      width: 400px;
      height: 265px;
      background-color: white;
      border-radius: 0 .5em .5em 0;
  
      @media only screen and (max-width: 788px) {
        width: 100%;
        height: auto;
        border-radius: 0 0 .5em .5em;
      }
    }
  }

  &:hover {
    transform: scale(1.02);

    .data-card {
    background: #02433A;
    }

    h3 {
      color: #FFFFFF;
      border-bottom-color: #FFFFFF;
    }

    h4 {
      color: #FFFFFF;
    }

    p {
      opacity: 1;
      transform: none;
    }

    .link-text {
      color: #FFFFFF;

      svg {
        animation: point 1.25s infinite alternate;
        
        path {
          fill: #FFFFFF;
        }
      }
    }
  }
}

@keyframes point {
  0% {
   transform: translateX(0);
  }
  100% {
    transform: translateX(.125em);
  }
}